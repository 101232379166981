/** @namespace Scandipwa/Util/Cache/initializeCache */
export const initializeCache = (name = 'common', assigned = window) => {
    if (name in assigned) {
        return;
    }
    assigned[name] = new Map();
};

/** @namespace Scandipwa/Util/Cache/getCacheValue */
export const getCacheValue = (name, cache = 'common', assigned = window) => {
    if (!(assigned[cache] instanceof Map)) {
        return;
    }

    return assigned[cache].get(name);
};

/** @namespace Scandipwa/Util/Cache/hasCacheValue */
export const hasCacheValue = (name, cache = 'common', assigned = window) => {
    if (!(assigned[cache] instanceof Map)) {
        return;
    }

    return assigned[cache].has(name);
};

/** @namespace Scandipwa/Util/Cache/setCacheValue */
export const setCacheValue = (name, value, cache = 'common', assigned = window) => {
    if (!(assigned[cache] instanceof Map)) {
        return;
    }

    return assigned[cache].set(name, value);
};
