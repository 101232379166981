import { createRef } from 'react';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps,
    NewsletterSubscriptionContainer as SourceNewsletterSubscriptionContainer
} from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.container';

import NewsletterSubscription from './NewsletterSubscription.component';

export {
    mapStateToProps
};

export const NewsletterSubscriptionDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/NewsletterSubscription/NewsletterSubscription.dispatcher'
);

/** @namespace Scandipwa/Component/NewsletterSubscription/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    subscribeToNewsletter: (email, name, consentIds) => NewsletterSubscriptionDispatcher.then(
        ({ default: dispatcher }) => dispatcher.subscribeToNewsletter(dispatch, email, name, consentIds)
    )
});

/** @namespace Scandipwa/Component/NewsletterSubscription/Container */
export class NewsletterSubscriptionContainer extends SourceNewsletterSubscriptionContainer {
    containerFunctions = {
        ...this.containerFunctions,
        onFormError: this.onFormError.bind(this)
    };

    consentsNewsletterRef = createRef();

    containerProps() {
        return {
            consentsNewsletterRef: this.consentsNewsletterRef
        };
    }

    state = {
        ...this.state,
        isSubmitted: false
    };

    onFormSubmit(fields) {
        const consentsNewsletter = this.consentsNewsletterRef.current.state;
        const { selectedConsentIds, areAllRequiredConsentsAccepted } = consentsNewsletter;

        if (!areAllRequiredConsentsAccepted) {
            this.onFormError();
            return;
        }

        const {
            subscribeToNewsletter,
            allowGuestSubscribe,
            isSignedIn,
            showErrorNotification
        } = this.props;
        const { newsletterName: name, newsletterEmail: email } = fields;

        const consentIds = Object.values(selectedConsentIds);

        if (!allowGuestSubscribe && !isSignedIn) {
            showErrorNotification(
                __('Guests can not subscribe to the newsletter. You must create an account or login to subscribe.')
            );

            return;
        }

        this.setState((prevState) => ({ ...prevState, isLoading: true }));

        subscribeToNewsletter(email, name, consentIds)
            .then(
                /** @namespace Scandipwa/Component/NewsletterSubscription/Container/NewsletterSubscriptionContainer/onFormSubmit/then/catch/subscribeToNewsletter/then */
                () => this.onFormSubmitDone({ email, name })
            )
            .catch(this.onFormSubmitDone);
    }

    onFormError() {
        this.setState((prevState) => ({ ...prevState, isSubmitted: true }));
    }

    onFormSubmitDone() {
        this.setState((prevState) => ({ ...prevState, isLoading: false }));
    }

    render() {
        return (
            <NewsletterSubscription
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsletterSubscriptionContainer);
