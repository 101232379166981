/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';
import CmsBlockProvider from 'Component/CmsBlockProvider';
import NewsletterSubscription from 'Component/NewsletterSubscription';
import { DeviceType } from 'Type/Device';

/**
 * Page footer
 * @class Footer
 * @namespace Scandipwa/Component/Footer/Component */
export class FooterComponent extends PureComponent {
    static propTypes = {
        isVisibleOnMobile: PropTypes.bool,
        device: DeviceType.isRequired
    };

    static defaultProps = {
        isVisibleOnMobile: true
    };

    renderSocialLinksContent() {
        return (
            <CmsBlock identifier="footer_social_links" />
        );
    }

    renderFooterContent() {
        return (
            <div block="Footer-Content">
                <CmsBlock identifier="footer_links" />
                <NewsletterSubscription />
            </div>
        );
    }

    renderCopyrightContent() {
        return (
            <CmsBlock identifier="footer_copyright" />
        );
    }

    render() {
        const { isVisibleOnMobile, device } = this.props;

        if (!isVisibleOnMobile && device.isMobile) {
            return null;
        }

        const identifiers = ['footer_social_links', 'footer_links', 'footer_copyright'];

        return (
            <CmsBlockProvider identifiers={ identifiers }>
                <footer block="Footer" aria-label="Footer">
                    { this.renderSocialLinksContent() }
                    { this.renderFooterContent() }
                    { this.renderCopyrightContent() }
                </footer>
            </CmsBlockProvider>
        );
    }
}

export default FooterComponent;
