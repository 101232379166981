import PropTypes from 'prop-types';

import CmsBlock from 'Component/CmsBlock/CmsBlock.component';
import withCmsBlockProvider from 'Component/CmsBlockProvider/CmsBlockProvider.hoc';
import CmsBlockQuery from 'Query/CmsBlock.query';
import { getCacheValue, hasCacheValue, setCacheValue } from 'Util/Cache';
import DataContainer from 'Util/Request/DataContainer';

/** @namespace Scandipwa/Component/CmsBlock/Container */
export class CmsBlockContainer extends DataContainer {
    static propTypes = {
        identifier: PropTypes.string.isRequired,
        cached: PropTypes.bool
    };

    static defaultProps = {
        cached: false
    };

    state = {
        cmsBlock: {}
    };

    componentDidMount() {
        this._getCmsBlock();
    }

    componentDidUpdate(prevProps) {
        const { blocks, identifier } = this.props;
        const { blocks: prevBlocks, identifier: prevIdentifier } = prevProps;

        if (prevBlocks && blocks && prevBlocks.length !== blocks.length) {
            this._getCmsBlock();
        }

        if (identifier !== prevIdentifier) {
            this._getCmsBlock();
        }
    }

    containerProps() {
        const { blockType } = this.props;
        const { cmsBlock } = this.state;

        return { cmsBlock, blockType };
    }

    async _getCmsBlock() {
        const {
            identifier, cached, shared, hasIdentifier, getBlock
        } = this.props;

        if (shared && hasIdentifier(identifier)) {
            this.setState({ cmsBlock: getBlock(identifier) });
        } else if (cached && hasCacheValue(identifier, 'block')) {
            this.setState({ cmsBlock: getCacheValue(identifier, 'block')[0] });
        } else {
            this.fetchData(
                [CmsBlockQuery.getQuery({ identifiers: [identifier] })],
                ({ cmsBlocks: { items } }) => {
                    if (!items.length) {
                        return;
                    }

                    if (cached) {
                        setCacheValue(identifier, items, 'block');
                    }

                    this.setState({ cmsBlock: items[0] });
                }
            );
        }
    }

    render() {
        return <CmsBlock { ...this.containerProps() } />;
    }
}

export default withCmsBlockProvider(CmsBlockContainer);
