/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import Button from 'Component/Button';
import Consents from 'Component/Consents';
import { CONSENTS_NEWSLETTER } from 'Component/Consents/Consents.config';
import FieldForm from 'Component/FieldForm';
import Form from 'Component/Form';

import './NewsletterSubscription.style';

/**
 * Newsletter Subscription form
 * @class NewsletterSubscription
 * @namespace Scandipwa/Component/NewsletterSubscription/Component */
export class NewsletterSubscriptionComponent extends FieldForm {
    static propTypes = {
        onFormSubmit: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isSubmitted: PropTypes.bool
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    get fieldMap() {
        const fields = {
            newsletterName: {
                validation: ['notEmpty'],
                placeholder: __('First name'),
                'aria-label': __('First name')
            },
            newsletterEmail: {
                validation: ['notEmpty', 'email'],
                placeholder: __('E-mail address'),
                'aria-label': __('Email address')
            }
        };

        return fields;
    }

    renderActions() {
        return (
            <Button
              type="submit"
              block="Button"
              mods={ { priority: 'other' } }
              mix={ { block: 'FieldForm', elem: 'Button' } }
              aria-label={ __('Subscribe') }
            >
                { __('Subscribe') }
            </Button>
        );
    }

    renderConsents() {
        const { isSubmitted, consentsNewsletterRef } = this.props;

        return (
            <Consents
              ref={ consentsNewsletterRef }
              isSubmitted={ isSubmitted }
              page={ CONSENTS_NEWSLETTER }
            />
        );
    }

    render() {
        const {
            isLoading, onFormSubmit, onFormError
        } = this.props;

        return (
            <div block="NewsletterSubscription">
                <Form
                  onSubmitError={ onFormError }
                  onSubmitSuccess={ onFormSubmit }
                  mix={ { block: 'FieldForm' } }
                >
                    <h3
                      block="TypographyHeader"
                      mods={ { heading: 'h4' } }
                      mix={ {
                          block: 'NewsletterSubscription',
                          elem: 'HeadingCMS'
                      } }
                    >
                        { __('Subscribe to the newsletter') }
                    </h3>
                    <div block="FieldForm" elem="Fieldset" mods={ { isLoading } }>
                        <div block="FieldForm">
                            { this.renderFields() }
                        </div>
                        <div block="FieldForm" elem="Agreement">
                            { this.renderConsents() }
                        </div>
                        <div block="ButtonContainer">
                            { this.renderActions() }
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default NewsletterSubscriptionComponent;
